import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudyPlusnet.module.css";

// import plusnetImg from "../../assets/plusnet-deodorants.jpeg"
import plusnetImg from "../../assets/plusnet.jpg"
import plusnet2Img from "../../assets/plusnet2.png"
import tyvisoLogo from "../../assets/simple-logo.png"

import TestimonoalImgOne from "../../assets/amy-metcalfe-digital-trading-manager-plusnet.jpg"
import TestimonoalImgTwo from "../../assets/adrian.jpg"
import Cta from '../styleguide/Cta';

const testimonials = [
  {
    id: 'plusnet',
    img: TestimonoalImgOne,
    alt: 'Amy Metcalfe Digital Trading Manager Plusnet',
    text: '"Working with Tyviso and Awin to launch our new Plusnet Perks platform has really moved the dial on how stakeholders see the channel overall.\n\nMutual dedication, attention to detail and excellent relationship building have all ensured we keep the customer at the heart of everything.\n\nIt’s an exciting time in Brand Partnerships, and we cannot wait to see where we will go next!"',
    author: 'Amy Metcalfe, Digital Trading Manager, Plusnet'
  },
  {
    id: 'tyviso',
    img: TestimonoalImgTwo,
    alt: 'Adrian Vella, CEO, Tyviso',
    text: '"Plusnet has bolstered its promise to customers with its new rewards offering — broadband with enormous benefits and high customer value. Clearly, their customers appreciate it, too!\n\nThe results of this initiative have been staggering and are excellent proof of what great brand partnerships and execution can have on brand loyalty and, ultimately, revenue."',
    author: 'Adrian Vella, CEO, Tyviso'
  }
]

const resultList = [
  {
    normal: 'more likely to complete an upgrade',
    bold: '90% '
  },
  {
    normal: 'more likely to update their marketing preferences',
    bold: '209% '
  },
  {
    normal: 'more likely to update their account details',
    bold: '46% '
  },
  {
    normal: 'more likely to return to the website',
    bold: '33% '
  },
  {
    pre: 'Spent ',
    normal: 'more time on site than non-My Perks visitors',
    bold: '33% '
  },
  {
    pre: 'Spent ',
    normal: 'longer on the page compared to the old perks page',
    bold: '39% '
  },
]

function CaseStudyPlusnet({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}>
        <FormattedMessage id={title} />
      </h2>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={plusnetImg}
          alt="Plusnet Broadband"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h3 className={styles.CaseStudySubTitle}>
            <FormattedMessage id={subTitle} />
          </h3>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="plusnetCaseFeaturedTextOne" />
          </p>
          <p className={styles.CaseStudyText}>
            <FormattedHTMLMessage id="plusnetCaseFeaturedTextTwo" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="plusnetCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="plusnetCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="plusnetCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="plusnetCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="plusnetCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="plusnetCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="plusnetCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="plusnetCaseApproachItemTwo" />
          </p>
          <p>
            <FormattedMessage id="plusnetCaseApproachItemThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={plusnet2Img} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="plusnetCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="plusnetCaseResultsItemOne" />
          </p>
          <ul>
            {
              resultList.map(({pre, bold, normal}, idx) => (
                <li key={`result-list--${idx}`}>
                  {pre}<strong>{bold}</strong>{normal}
                </li>
              ))
            }
          </ul>
          <p>
            <FormattedMessage id="plusnetCaseResultsItemTwo" />
          </p>
          <ul>
            <li className='bold'>
              <FormattedMessage id="plusnetCaseResultsItemThree" />
            </li>
          </ul>
          <p>
            <FormattedMessage id="plusnetCaseResultsItemFour" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>

      <div className={styles.CaseStudyLaunch}>
        <h3><FormattedMessage id="perksCaseLaunchTitle" /></h3>
        <p><FormattedMessage id="perksCaseLaunchDesc" /></p>

        <Cta className={styles.Cta} link="/contact" text="contactUs" />
      </div>
    </div>
  );
};

export default CaseStudyPlusnet;