import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudyPlusnet from "../../components/info-site/CaseStudyPlusnet";
import GetStarted from "../../components/info-site/GetStarted"

const WildCase = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="plusnetCaseTitle" descriptionId="plusnetCaseSubTitle" />
            <CaseStudyPlusnet
              title="plusnetCaseTitle"
              subTitle="plusnetCaseSimpleSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default WildCase;